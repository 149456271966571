import { Controller } from '@hotwired/stimulus';

import TomSelect from 'tom-select';

// Connects to data-controller="tomselect"
export default class extends Controller {
  connect() {
    this.tomSelectInstance = new TomSelect(this.element, {
      sortField: {
        field: 'text',
        direction: 'asc',
      },
    });
  }

  disconnect() {
    // This is called when the element is removed from the DOM
    this.tomSelectInstance.destroy();
  }
}
