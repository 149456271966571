// Entry point for the build script in your package.json
import '@hotwired/turbo-rails';
import './controllers';
import 'bootstrap';
import Trix from 'trix';
import '@rails/actiontext';
import 'chartkick/chart.js';

// custom Trix buttons
// https://stackoverflow.com/questions/58805984/add-buttons-to-rails-actiontext-toolbar
document.addEventListener('trix-before-initialize', (event) => {
  // Allow h2 as a block attribute
  Trix.config.blockAttributes.heading2 = {
    tagName: 'h2',
  };
  /* insert the button visual in the default toolbar */
  const buttonHTML = '<button type="button" class="trix-button" data-trix-attribute="heading2">H2</button>';
  event.target.toolbarElement
    .querySelector('.trix-button-group')
    .insertAdjacentHTML('beforeend', buttonHTML);
});
