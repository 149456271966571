import { Controller } from '@hotwired/stimulus';

import Inputmask from 'inputmask';

// Connects to data-controller="inputmask"
export default class extends Controller {
  connect() {
    Inputmask({ mask: this.element.dataset.pattern }).mask(this.element);
  }
}
