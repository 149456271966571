import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['qty', 'linePrice'];

  static values = { price: Number };

  connect() {
    this.changed();
  }

  changed() {
    const total = this.qtyTarget.value * parseFloat(this.priceValue);
    this.linePriceTarget.textContent = total;
  }
}
